@font-face {
  font-family : "Soho Pro";
  src         : url("../webfonts/63e0ab65-83cd-4927-a0c6-2e41298a43ed.eot?#iefix");
  src         : url("../webfonts/63e0ab65-83cd-4927-a0c6-2e41298a43ed.eot?#iefix") format("eot"), url("../webfonts/51672716-570a-4658-9324-e5168b406b89.woff2") format("woff2"), url("../webfonts/49339b19-a568-4e06-8d7a-eb751f466d39.woff") format("woff"), url("../webfonts/7cacda2f-e7b8-44cc-a993-0d8467d01838.ttf") format("truetype"), url("../webfonts/576e4f32-4fdd-4305-a5ba-efee7439de7f.svg#576e4f32-4fdd-4305-a5ba-efee7439de7f") format("svg");
  font-weight : 500;
  font-style  : normal;
}
@font-face {
  font-family : "Soho Gothic";
  src         : url("../webfonts/b9715fdb-09a5-4b7e-be15-69e328c1b44a.eot?#iefix");
  src         : url("../webfonts/b9715fdb-09a5-4b7e-be15-69e328c1b44a.eot?#iefix") format("eot"), url("../webfonts/598b0845-9116-49c3-921c-0faad7a564df.woff2") format("woff2"), url("../webfonts/df20967e-6948-4e78-a4a5-bae27b4a03b9.woff") format("woff"), url("../webfonts/d6e767ca-8efd-405f-b1e0-964985cb73f9.ttf") format("truetype"), url("../webfonts/6b2eeb65-8052-4e20-9b95-402b3ef267a3.svg#6b2eeb65-8052-4e20-9b95-402b3ef267a3") format("svg");
  font-weight : 500;
  font-style  : normal;
}
@font-face {
  font-family : "Soho Gothic";
  src         : url("../webfonts/f00c71d9-ce92-430e-a6ed-b31ccd82b561.eot?#iefix");
  src         : url("../webfonts/f00c71d9-ce92-430e-a6ed-b31ccd82b561.eot?#iefix") format("eot"), url("../webfonts/b31e563f-1064-4cb3-b694-d181e38a93c7.woff2") format("woff2"), url("../webfonts/69b3c7b7-6d19-4296-b37f-d0463e04a20f.woff") format("woff"), url("../webfonts/93ce0cc9-9be6-44b4-9991-6f5e4e6f7d72.ttf") format("truetype"), url("../webfonts/c472ea93-d57f-4511-831c-996479d8f7a2.svg#c472ea93-d57f-4511-831c-996479d8f7a2") format("svg");
  font-weight : 400;
  font-style  : normal;
}
