@media screen and (max-height : 934px) {
  .left-sidebar {
    overflow-y     : auto;
    padding-bottom : 0;
    padding-top    : 15px;

    h1, h2, h3, h4 {
      margin-bottom : 5px
    }

    .navigation {
      margin-bottom : 20px;
    }

    .ts-site-title {
      margin-bottom : 10px;
    }
  }

  .right-sidebar {
    padding-top : 30px;

    .right-sidebar__overlay {
      padding-top : 30px;
    }
  }
}
@media screen and (max-height : 825px) {
  .extra-link {
    display : none;
  }
  .navigation {
    margin-bottom : 0;
  }
}
@media screen and (max-height : 675px) {
  .navigation__item {
    padding : 5px 0 5px 0;
  }

}
@media screen and (max-width : 1200px) {
  .main-container {
    margin-left  : 0;
    padding-left : 0;
  }
}
