/*
    ===============================
    COMMON CODE
    ===============================
*/
@mixin antialias {
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
}
@mixin checkbox-hide {
  border   : 0;
  clip     : rect(0 0 0 0);
  height   : 1px;
  margin   : -1px;
  overflow : hidden;
  padding  : 0;
  position : absolute;
  width    : 1px;
}
$z-index : (
        header: 20,
        footer: 10,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}
@mixin z-index($key) {
  z-index : z-index($key);
}
/*
    ===============================
    TYPOGRAPHY
    ===============================
*/

/* Font families */
@mixin sans {
  font-family : 'Soho Gothic', Arial, Helvetica, sans-serif;
}
@mixin serif {
  font-family : 'Soho Pro', Georgia, "Times New Roman", sans-serif;
}
/* Body text */
@mixin ts-body {
  @include sans;
  color                    : $navy--dark;
  font-size                : 14px;
  font-weight              : 400;
  line-height              : 26px;
  -webkit-text-size-adjust : none;
}
/*
    ===============================
    EM CALCULATOR
    ===============================
*/
@function em($px, $base : 18px) {
  @return ($px / $base) * 1em;
}
/*
    ===============================
    SVG SPRITE AND FALLBACK
    ===============================
*/

//Pixel Sized
@mixin sprite($x, $y) {
  background          : url('../images/sprite.png') $x $y; // IE7, IE8
  background-image    : url('../images/sprite.svg'), none; // IE9+, FF, Chrome, Safari
  background-position : $x $y;
  background-repeat   : no-repeat;
  background-size     : 460px 110px; //Constant size of sprite, width height
}
// SVG Image with PNG Fallback
@mixin svg_image($image, $size, $x : 0, $y : 0) {
  background          : url('../images/'+($image)+'.png') $x $y; // IE7, IE8
  background-image    : url('../images/'+($image)+'.svg'), none; // IE9+, FF, Chrome, Safari
  background-position : $x $y;
  background-repeat   : no-repeat;
  background-size     : $size; //Size of PNG
}
