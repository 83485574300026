/*
 * perfect-scrollbar v0.6.10
 */
$rail-bg-color : #DFDFDF;
$rail-color : #A9A9A9;

.ps-container {
  -ms-touch-action   : none;
  touch-action       : none;
  overflow           : hidden !important;
  -ms-overflow-style : none;
}
@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow : auto !important;
  }
}
@media screen and (-ms-high-contrast : active), (-ms-high-contrast : none) {
  .ps-container {
    overflow : auto !important;
  }
}
.ps-scrollbar-x-rail,
.ps-scrollbar-y-rail {
  opacity : 1;
  z-index : 5;
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display          : block;
  background-color : $rail-bg-color;
}
.ps-container.ps-in-scrolling {
  pointer-events : none;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color : $rail-color;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color : $rail-color;
}
.ps-container > .ps-scrollbar-x-rail {
  display               : none;
  position              : absolute;
  /* please don't change 'position' */
  -webkit-border-radius : 0px;
  -moz-border-radius    : 0px;
  border-radius         : 0px;
  //opacity               : 0;
  -webkit-transition    : background-color .2s linear, opacity .2s linear;
  -moz-transition       : background-color .2s linear, opacity .2s linear;
  -o-transition         : background-color .2s linear, opacity .2s linear;
  transition            : background-color .2s linear, opacity .2s linear;
  bottom                : 3px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height                : 8px;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position              : absolute;
  /* please don't change 'position' */
  background-color      : #AAAAAA;
  -webkit-border-radius : 0px;
  -moz-border-radius    : 0px;
  border-radius         : 0px;
  -webkit-transition    : background-color .2s linear;
  -moz-transition       : background-color .2s linear;
  -o-transition         : background-color .2s linear;
  transition            : background-color .2s linear;
  bottom                : 0;
  /* there must be 'bottom' for ps-scrollbar-x */
  height                : 8px;
}
.ps-container > .ps-scrollbar-y-rail {
  display               : none;
  position              : absolute;
  /* please don't change 'position' */
  -webkit-border-radius : 0px;
  -moz-border-radius    : 0px;
  border-radius         : 0px;
  // opacity               : 0;
  -webkit-transition    : background-color .2s linear, opacity .2s linear;
  -moz-transition       : background-color .2s linear, opacity .2s linear;
  -o-transition         : background-color .2s linear, opacity .2s linear;
  transition            : background-color .2s linear, opacity .2s linear;
  right                 : 0; //15px;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width                 : 8px;
  margin-top            : 0; //15px;
  margin-bottom         : 0; //65px;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position              : absolute;
  /* please don't change 'position' */
  background-color      : #AAAAAA;
  -webkit-border-radius : 0px;
  -moz-border-radius    : 0px;
  border-radius         : 0px;
  -webkit-transition    : background-color .2s linear;
  -moz-transition       : background-color .2s linear;
  -o-transition         : background-color .2s linear;
  transition            : background-color .2s linear;
  right                 : 0;
  /* there must be 'right' for ps-scrollbar-y */
  width                 : 8px;
}
.ps-container:hover.ps-in-scrolling {
  pointer-events : none;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color : $rail-color;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color : $rail-color;
}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity : 1;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color : $rail-color;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color : $rail-bg-color;
  opacity          : 1;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color : $rail-color;
}
