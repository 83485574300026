/*
  Layout
  ========================================================================== */
$left-sidebar-width : 320px;

/*
  General
  ========================================================================== */
$black : #000000;
$white : #FFFFFF;
$off-white : #F4F6F7;
$white--off-white : $off-white;

$colours : black white;
$colour_var : $black $white;

/*
  Navy
  ========================================================================== */
$navy : #2B5464;
$navy--dark : #2B5464;
$navy--mid : #406573;
$navy--light : #6B8792;
$navy--pale : #BFCCD0;
/*
  Blue
  ========================================================================== */
$blue : #0099DA;
$blue--dark : #0099DA;
$blue--light : #5BCBF5;
$blue--mid-grey : #9EB3BE;
$blue--light-grey : #B5D5E5;
/*
  Green
  ========================================================================== */
$green : #A6CE39;
$green--dark-grey : #616966;
$green--mid-grey : #A9B5AF;
$green--light-grey : #C4D7D4;
/*
  Pink
  ========================================================================== */
$pink : #E23D96;
$pink--dark-grey : #61616A;
$pink--mid-grey : #ACA2B1;
$pink--light-grey : #D8C9CC;
/*
  Orange
  ========================================================================== */
$orange : #F55520;
$orange--dark : #F55520;
$orange--light : #F58220;
$orange--dark-grey : #63635D;
$orange--mid-grey : #BDB4AB;
$orange--light-grey : #DFCFB6;
/*
  Yellow
  ========================================================================== */
$yellow : #E4B417;
$yellow--dark : #E4B417;
$yellow--light : #FCCB05;

/*
 * By filling in the lists below, you will automatically generate utility colour and background colour classes for all the colour variables.
 * The $colours list should be a space separated list of names; these will be used in the naming of the class i.e .u-black
 * The $_colour_var should list all the variables you want to include
 *
 * The classes are generated within utilities.scss and will produce both .u-black and .u-black-bg (for example)
 */
$colours : white navy-dark blue green pink orange yellow;
$colour_var : $white $navy--dark $blue $green $pink $orange $yellow;
