/*
    ===============================
    RESET
    ===============================
*/
@import "partials/reset";
/*
    ===============================
    MIXINS
    ===============================
*/
@import "partials/mixins";
/*
    ===============================
    VARIABLES
    ===============================
*/
@import 'partials/variables';
/*
    ===============================
    UTILITIES
    ===============================
*/
@import 'partials/utilities';
/*
    ===============================
    TYPOGRAPHY
    ===============================
*/
@import "partials/fontface";
@import "partials/text-styles";
/*
    ===============================
    STYLES
    ===============================
*/
.is-disabled {
  opacity : 0.3 !important;
}
.main-container {
  margin-left : 50px;
  padding     : 0 450px 0 20px;
  text-align  : center;
}
html {
  height     : 100vh;
  max-height : 100vh;
  max-width  : 100vw;
  min-width  : 100vw;
  overflow   : hidden;
  width      : 100vw;
}
body {
  box-sizing : border-box;
  height     : 100vh;
  min-width  : 100vw;
  max-width  : 100vw;
  max-height : 100vh;
  overflow   : hidden;
  position   : relative;
  width      : 100vw;
}
/*
  SVG Styles
  ========================================================================== */
.svg-container > svg {
  height     : 100vh;
  margin-top : -45px;
  opacity    : 0;
  overflow   : visible;
  position   : relative;
  transition : opacity 0.5s;
  z-index    : 2;

  > g {
    cursor : pointer;
    .js-pin {
      opacity : 0;
    }

    &.is-active {
      .js-pin {
        opacity : 1;
      }
    }

    &.is-small {
      cursor : pointer;
      & > g {
        opacity : 0.5;
      }

      &:hover {
        & > g {
          opacity : 1;
        }
      }
    }
  }

  .height-fix {
    opacity : 0;
  }
}
.js-pin:hover, .js-pin[data-state='active'], .js-pin-target:hover + .js-pin {
  circle.st1 {
    fill         : $white;
    stroke       : $navy;
    stroke-width : 2px;
  }

  .st4 {
    fill : $navy;
  }
}
/*
   Left Sidebar
   ========================================================================== */
.left-sidebar {
  background-color       : $navy--dark;
  box-shadow             : -6px 0 5px 10px rgba(0, 0, 0, 0.4);
  box-sizing             : border-box;
  display                : block;
  height                 : 100%;
  left                   : 0;
  overflow               : hidden;
  padding                : 30px 0;
  position               : absolute;
  top                    : 0;
  transition             : width 0.3s, opacity 0.5s;
  width                  : 50px;
  z-index                : 12;
  -webkit-font-smoothing : antialiased;

  &__footer {
    background-color : $navy--dark;
    border-top       : 1px solid $navy--light;
    bottom           : 0;
    min-height       : 50px;
    left             : 0;
    position         : absolute;
    width            : 100%;
    z-index          : 2;

    .social-btn, .download-btn, .info-btn {
      box-sizing     : border-box;
      display        : inline-block;
      height         : 50px;
      text-align     : center;
      vertical-align : middle;
      width          : 50px;

      &:hover, &:focus, &.is-active {
        background-color : $navy--mid
      }

      &:hover svg, &:focus svg {
        path, polygon, circle {
          //fill : $blue;
        }
      }
    }

    &__info-box {
      background-color : $navy--mid;
      border-top       : 1px solid $navy--light;
      bottom           : 51px;
      display          : none;
      left             : 0;
      padding          : 25px 16px;
      position         : absolute;
    }

    .social-btn {
      svg {
        margin-top : 10px;
      }

      & + .social-btn {
        margin-left : -8px;
      }
    }

    .download-btn {
      border-left : 1px solid $navy--light;
      float       : right;

      svg {
        margin-top : 13px;
      }
    }

    .info-btn {
      border-left : 1px solid $navy--light;
      float       : right;

      svg {
        margin-top : 10px;
      }

      &:hover {
        cursor : pointer;
      }
    }
  }

  h1, h2, h3, h4 {
    box-sizing    : border-box;
    padding-left  : 16px;
    padding-right : 16px;
  }

  &.is-expanded {
    width : $left-sidebar-width;

    .hide-when-closed {
      opacity    : 1;
      visibility : visible;
    }

    .raeng-logo {
      height      : 60px;
      margin-left : 10px;
      width       : 150px;

      &__text {
        opacity : 1;
      }
    }
  }

  .hide-when-closed {
    opacity          : 0;
    visibility       : hidden;
    width            : $left-sidebar-width;
    transition       : opacity 0.3s;
    transition-delay : 0.2s;
  }
}
// Hamburger Menu
.hamburger {
  cursor     : pointer;
  display    : block;
  height     : 25px;
  position   : absolute;
  right      : 13px;
  top        : 34px;
  transition : right 0.3s;
  width      : 24px;

  &__line {
    background-color : $white;
    border-radius    : 4px;
    display          : block;
    height           : 2px;
    position         : absolute;
    width            : 100%;

    &--top {
      top              : 0;
      transform-origin : 50% 50%;
      transition       : transform 0.3s ease, top 0.3s 0.3s ease;
    }

    &--middle {
      top        : 10px;
      transition : background 0.3s ease;
    }

    &--bottom {
      top              : 20px;
      transform-origin : 50% 50%;
      transition       : transform 0.3s ease, top 0.3s 0.3s ease;
      margin-bottom    : 0;
    }
  }

  &.is-cross {
    right : 16px;

    .hamburger__line {
      top : 11px;
    }

    .hamburger__line--top {
      transform        : rotate(-45deg);
      transform-origin : 50% 50%;
      transition       : top 0.3s ease, transform 0.3s 0.3s ease;
    }
    .hamburger__line--middle {
      background-color : transparent;
    }
    .hamburger__line--bottom {
      transform        : rotate(45deg);
      transform-origin : 50% 50%;
      transition       : top 0.3s ease, transform 0.3s 0.3s ease;
    }

    &:hover {
      .hamburger__line--top, .hamburger__line--bottom {
        background-color : $navy--pale;
      }
      &:before {
        background-color : transparent;
      }
    }
  }

  &:hover:before {
    background-color : $navy--mid;
    display          : block;
    content          : '';
    height           : 50px;
    left             : -13px;
    position         : absolute;
    top              : -14px;
    width            : 50px;
  }
}
// Tooltips
.tooltip {
  background-color : $navy--mid;
  left             : 70px;
  max-width        : 200px;
  padding          : 5px 10px;
  position         : absolute;
  z-index          : 1000;

  &:before {
    border             : solid transparent;
    border-color       : rgba(194, 225, 245, 0);
    border-right-color : $navy--mid;
    border-width       : 10px;
    content            : " ";
    height             : 0;
    margin-top         : -10px;
    position           : absolute;
    pointer-events     : none;
    right              : calc(100% - 1px);
    top                : 50%;
    width              : 0;
  }
}
.extra-link {
  display : block;
  width   : $left-sidebar-width;

  &__text {
    display : none;
  }

  &:hover, &:focus {
    .raeng-logo__text {
      & > g path {
        fill : $navy--pale;
      }
    }
    .raeng-logo__icon {
      & > g path {
        fill : none;
      }

      .raeng-logo__icon__part {
        path {
          fill : $navy--pale;
        }
      }
    }
  }
}
.raeng-logo {
  height      : 44px;
  margin-left : 10px;
  transition  : all 0.3s;
  width       : 110px;

  &__text {
    opacity : 0;

    &:hover, &:focus {
      & > g path {
        fill : $navy--pale;
      }
    }
  }
}
/*
  Navigation
  ========================================================================== */
.navigation {
  margin-bottom : 94px;
  width         : 350px;

  &__item {
    box-sizing     : border-box;
    display        : inline-block;
    padding        : 12px 0;
    position       : relative;
    vertical-align : middle;
    width          : 100%;
    z-index        : 1;

    &:hover, &:focus, &.is-active {
      background-color : $navy--mid;
      border-color     : $navy--light;
      cursor           : pointer;
      z-index          : 2;
    }

    &:first-child {
      border-top : 1px solid $navy--light;
    }

    &--has-bottom-border {
      border-bottom : 1px solid $navy;
    }

    &__text {
      display        : inline-block;
      margin-top     : -8px;
      vertical-align : middle;
      width          : 70%;
      width          : calc(100% - 80px);
    }

    &__circle {
      border-radius  : 40px;
      display        : inline-block;
      height         : 20px;
      margin-left    : 16px;
      margin-right   : 20px;
      margin-top     : -6px;
      vertical-align : middle;
      width          : 20px;

      &--white {
        background-color : $white;
      }

      &--blue {
        background-color : $blue;
      }

      &--green {
        background-color : $green;
      }

      &--yellow {
        background-color : $yellow;
      }

      &--orange {
        background-color : $orange;
      }

      &--pink {
        background-color : $pink;
      }

      &--white-halo {
        background-color : transparent;
        border           : 2px solid $white;
        box-sizing       : border-box;

        .is-active & {
          background-color : $white;
        }
      }
    }
  }

  &--case-studies {
    margin-bottom : 20px;
    .navigation__item {
      border-color : $navy--light;
      min-height   : 74px;

      &__text {
        margin-top : 0;
      }

      &__circle {
        margin-top : 0;
      }

      &:hover, &:focus {
        .navigation__item__circle--white-halo {
          background-color : $white;
        }
      }
    }
  }
}
/*
  Right Sidebar
  ========================================================================== */
.right-sidebar {
  background-color : $white--off-white;
  box-sizing       : border-box;
  display          : block;
  height           : 100%;
  overflow         : visible;
  padding          : 73px 10px 50px 20px;
  position         : absolute;
  right            : 0;
  top              : 0;
  min-width        : 260px;
  max-width        : 450px;
  width            : 25%;
  z-index          : 10;
  opacity          : 1;
  transition       : opacity 0.5s, right 0.7s;

  &__main {
    height        : 100%;
    overflow      : scroll;
    padding-right : 30px;
    position      : relative;
  }

  &__footer {
    border-bottom : 1px solid $navy--pale;
    border-top    : 1px solid $navy--pale;
    bottom        : 50px;
    left          : 0;
    position      : absolute;
    width         : 100%;
    height        : 50px;
  }

  .sidebar-nav {
    display : none;
  }

  &.is-case-study {
    padding-top : 53px;

    /*.right-sidebar__main__title:before {
      @include sans;
      content        : ' Case study ';
      display        : block;
      font-size      : 16px;
      font-weight    : 500;
      line-height    : 26px;
      margin-bottom  : 6px;
      text-transform : uppercase;
    }*/
  }

  &.show-explore {
    padding-bottom : 110px;

    .sidebar-nav {
      display : block;
    }
  }

  // Right Sidebar overlay
  &__overlay {
    background-color : $white;
    box-shadow       : -4px 0px 5px 0 rgba(0, 0, 0, 0.1);
    box-sizing       : border-box;
    display          : block;
    height           : 100%;
    overflow         : hidden;
    padding          : 73px 10px 110px 20px;
    position         : absolute;
    right            : -100%;
    top              : 0;
    min-width        : 260px;
    max-width        : 450px;
    transition       : right 0.5s;
    width            : 100%;
    z-index          : 10;

    &.is-visible {
      right : 0;
    }

    &__main {
      box-sizing    : border-box;
      height        : 100%;
      max-width     : 100%;
      padding-right : 30px;
      position      : relative;
      overflow      : hidden;
      word-break    : break-word;
    }

    &__footer {
      border-bottom : 1px solid $navy--pale;
      border-top    : 1px solid $navy--pale;
      bottom        : 50px;
      left          : 0;
      position      : absolute;
      width         : 100%;
      height        : 50px;
    }
  }
}
.sidebar-nav {
  &__prev, &__next, &__explore {
    box-sizing     : border-box;
    display        : inline-block;
    height         : 100%;
    opacity        : 1;
    transition     : opacity 0.3s;
    vertical-align : middle;
    width          : 50%;

    &:hover, &:focus {
      background-color : $navy--pale;
      cursor           : pointer;
    }

    &__icon {
      display        : inline-block;
      height         : 20px;
      margin-left    : 20px;
      margin-right   : 20px;
      margin-top     : 14px;
      vertical-align : middle;
      width          : 10px;
    }
    &__text {
      display        : inline-block;
      margin-top     : 13px;
      vertical-align : middle;
    }

    &.is-hidden {
      opacity : 0;
    }
  }

  &__prev {
    border-right : 1px solid $navy--pale;

    &.is-hidden + .sidebar-nav__next {
      border-left : 1px solid $navy--pale;
    }
  }

  &__next {
    margin-left : -4px;
    text-align  : right;
  }

  &__explore {
    padding-left : 16px;
    width        : 100%;

    &__icon {
      float : right;
    }
  }
}
.close {
  cursor     : pointer;
  display    : block;
  height     : 25px;
  position   : absolute;
  right      : 20px;
  top        : 27px;
  transition : right 0.3s;
  width      : 24px;
  z-index    : 20;

  &:hover {
    &:before {
      background-color : transparent;
    }

    .close__line {
      background-color : $navy--light;
    }
  }

  &__line {
    background-color : $navy;
    border-radius    : 4px;
    display          : block;
    height           : 2px;
    position         : absolute;
    top              : 11px;
    width            : 100%;

    &--top {
      transform        : rotate(-45deg);
      transform-origin : 50% 50%;
    }

    &--bottom {
      margin-bottom    : 0;
      transform        : rotate(45deg);
      transform-origin : 50% 50%;
    }
  }
}
/*
  Overlays
  ========================================================================== */
.overlay {
  cursor     : pointer;
  height     : 100%;
  left       : 0;
  position   : absolute;
  text-align : center;
  top        : 0;
  width      : 100%;
  z-index    : 100;

  &:before {
    background-color : $navy;
    content          : '';
    display          : block;
    height           : 100%;
    left             : 0;
    position         : absolute;
    opacity          : 0.2;
    top              : 0;
    width            : 100%;
  }

  &__box {
    background-color : #FFFFFF;
    box-shadow       : 0 0 20px 0 rgba(120, 120, 120, 0.4);
    box-sizing       : border-box;
    cursor           : default;
    display          : inline-block;
    margin-top       : -135px;
    margin-left      : auto;
    margin-right     : auto;
    max-width        : 70vw;
    min-width        : 500px;
    min-height       : 270px;
    padding          : 50px 50px 40px;
    position         : relative;
    top              : 46%;
    vertical-align   : middle;
    z-index          : 200;

    &__close {
      background-color : $navy;
      border-radius    : 50px;
      box-shadow       : 0 0 10px 0 rgba(120, 120, 120, 0.8);
      cursor           : pointer;
      height           : 50px;
      position         : absolute;
      right            : -25px;
      top              : -25px;
      width            : 50px;

      .close {
        right : 13px;
        top   : 13px;
      }

      .close__line {
        background-color : $white;
      }

      &:hover {
        background-color : $navy--mid;

        .close:hover:before {
          background-color : transparent;
          content          : '';
        }
      }
    }
  }

  &--mobile {
    display : none;

    &:before {
      opacity : 0.6;
    }
  }

  &--menu {
    display : none;
    z-index : 11;
  }

  &--intro {
    &__logo {
      margin-top : 20px;
    }
  }

}
/*
  Case Studies
  ========================================================================== */
.case-studies {
  background-color : $white;
  display          : none;
  height           : 100%;
  left             : 0;
  position         : absolute;
  top              : 0;
  width            : 75%;
  z-index          : 10;

  &__inner {
    padding   : 60px 0 20px;
    position  : absolute;
    top       : 50%;
    transform : translateY(-50%);
    width     : 100%;

    .no-csstransforms3d & {
      top       : 0;
      transform : none;
    }
  }

  &__row {
    text-align : center;

    &__title {
      margin-bottom : 0;
    }

    &__icons {
      padding : 14px 40px 23px 40px;
    }
  }
}
.case-studies-icon {
  display        : inline-block;
  margin-bottom  : 17px;
  vertical-align : top;

  & + .case-studies-icon {
    margin-left : 16px;
  }

  &.is-active {
    .Highlight_1_ {
      opacity : 1;
    }

    &:hover, &.is-selected {
      cursor : pointer;

      .Highlight_1_ .u-navy-dark-fill {
        fill : $white;
      }

      .blue-icon {
        .u-white-fill {
          fill : $blue;
        }
      }
      .green-icon {
        .u-white-fill {
          fill : $green;
        }
      }
      .orange-icon {
        .u-white-fill {
          fill : $orange;
        }
      }
      .pink-icon {
        .u-white-fill {
          fill : $pink;
        }
      }
      .yellow-icon {
        .u-white-fill {
          fill : $yellow;
        }
      }
    }
  }

  svg {
    border-radius : 25px;
    box-shadow    : 3px 3px 12px 0px rgba(204, 204, 204, 1);
  }

  .Highlight_1_ {
    opacity    : 0;
    transition : all 0.3s;
  }
}
.external-link {
  background    : url(http://upload.wikimedia.org/wikipedia/commons/6/64/Icon_External_Link.png) no-repeat right center;
  padding-right : 16px;
}
/*
    ===============================
    PLUGINS
    ===============================
*/
@import 'partials/plugins';

/*
    ===============================
    RESPONSIVE
    ===============================
*/
@import 'partials/responsive';
