/*
  Headings
  ========================================================================== */
.ts-heading-1 {
  @include sans;
  // Color is layer dependant
  color         : $navy;
  font-size     : 22px;
  font-weight   : 500;
  line-height   : 34px;
  margin-bottom : 14px;
}
.ts-heading-3 {
  @include sans;
  font-size      : 16px;
  font-weight    : 500;
  line-height    : 26px;
  margin-bottom  : 6px;
  text-transform : uppercase;
}
.ts-site-title {
  @include serif;
  color         : $blue--dark;
  font-size     : 30px;
  font-weight   : 500;
  line-height   : 40px;
  margin-bottom : 30px;

  &--small {
    font-size   : 22px;
    line-height : 32px;
  }
}
/*
  Navigation
  ========================================================================== */
.ts-nav {
  @include sans;
  color       : $white;
  font-size   : 18px;
  font-weight : 400;
  line-height : 26px;
}
.ts-nav-case-study {
  @include sans;
  color       : $white;
  font-size   : 16px;
  font-weight : 400;
  line-height : 26px;
}
.ts-nav-heading {
  @include sans;
  color          : $navy--pale;
  font-size      : 14px;
  font-weight    : 400;
  letter-spacing : 0.75px;
  line-height    : 26px;
  margin-bottom  : 13px;
}
.ts-nav-note {
  @include sans;
  color       : $white;
  font-size   : 12px;
  font-weight : 400;
  line-height : 18px;

  a {
    color           : $white;
    text-decoration : underline;

    &:hover {
      color : $blue;
    }
  }
}
/*
  Content
  ========================================================================== */

.ts-body {
  @include ts-body;
  margin-bottom : 14px;
}
.ts-intro-text {
  @include sans;
  color       : $navy--dark;
  font-size   : 20px;
  font-weight : 400;
  line-height : 36px;
}
.the-content a {
  @include sans;
  border-bottom   : 1px solid $navy--dark;
  color           : $navy--dark;
  font-weight     : 400;
  font-size       : 14px;
  line-height     : 26px;
  padding-bottom  : 3px;
  text-decoration : none;

  &:hover, &:focus {
    border-style : dotted;
  }
}
.ts-cap-text {
  @include sans;
  color          : $white;
  font-size      : 16px;
  font-weight    : 500;
  letter-spacing : 0.25px;
  line-height    : 26px;
  text-transform : uppercase;

  &--navy {
    color : $navy;
  }
}
.ts-case-study {
  @include sans;
  color          : $navy--light;
  font-size      : 16px;
  font-weight    : 400;
  line-height    : 26px;
  letter-spacing : 0.25px;
  text-align     : center;
  text-transform : uppercase;
}
/*
  Miscellaneous
  ========================================================================== */

.ts-align-center {
  text-align : center;
}
