// Colours
@each $name in $colours {
  $i : index($colours, $name);
  .u-#{$name}-fill {
    fill : nth($colour_var, $i);
  }
}
// Positioning
.u-clear {
  clear      : both;
  display    : block;
  overflow   : hidden;
  visibility : hidden;
  width      : 0;
  height     : 0;
}
.u-left {
  float : left;
}
.u-right {
  float : right;
}
// Miscellaneous
.u-hidden {
  display : none;
}
.u-anchor {
  display    : block;
  margin-top : -140px;
  position   : absolute;
  visibility : hidden;
}
.u-relative {
  position : relative;
}
